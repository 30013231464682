// ToDo: English version missing

import React from 'react';
import { graphql } from 'gatsby';
import { getCookieConsentValue, Cookies } from 'react-cookie-consent';
import { useIntl, FormattedMessage } from 'gatsby-plugin-react-intl';

import { Box, Typography } from '@mui/material';

import Layout from '../components/layout/Layout';
import Seo from '../components/Seo';
import { Markdown, Container, Button } from '../components/theme';

// cookieConsent: {
//   display: 'block',
//   '& > button': {
//     margin: '10px 20px 10px 0',
//   },
// },

function PrivacyPage({
  data: {
    allStrapiAppPrivacyStatement: { nodes },
  },
}) {
  const { privacyText, privacyText2 } = nodes[0];

  const intl = useIntl();

  return (
    <Layout lightTheme>
      <Container narrow>
        <Markdown source={privacyText.data.Text} />
        <Markdown source={privacyText2.data.Text2} />
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    allStrapiAppPrivacyStatement {
      nodes {
        privacyText: Text {
          data {
            Text
          }
        }
        privacyText2: Text2 {
          data {
            Text2
          }
        }
        title: Title
        description: Description
      }
    }
  }
`;

export default PrivacyPage;

export function Head({
  // eslint-disable-next-line react/prop-types
  data: {
    // eslint-disable-next-line react/prop-types
    allStrapiAppPrivacyStatement: { nodes },
  },
}) {
  // eslint-disable-next-line react/prop-types
  const { title, description } = nodes[0];

  return <Seo title={title} description={description} />;
}
